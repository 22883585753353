<!--
 * @Description: 后续处理弹窗
 * @Author: luocheng
 * @Date: 2021-10-26 20:19:43
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-27 15:58:44
-->
<template>
	<div class="follow-up">
		<el-dialog
			title="事故后续处理"
			:visible.sync="value"
			modal-append-to-body
			append-to-body
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:destroy-on-close="true"
			width="300"
		>
			<article class="modify-content">
				<CommonTitle :title="'基本信息'"></CommonTitle>
				<el-form
					:model="followForm"
					ref="followForm"
					:rules="rules"
					label-width="110px"
				>
					<el-form-item
						label="填报时间"
						prop="date"
					>
						<el-date-picker
							v-model="followForm.date"
							type="date"
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item
						label="事故后续处理"
						prop="follow"
					>
						<el-input
							v-model="followForm.follow"
							type="textarea"
							placeholder="请输入事故后续处理"
							clearable
						></el-input>
					</el-form-item>
				</el-form>
			</article>
			<span
				slot="footer"
				class="dialog-footer"
			>
				<el-button @click="onClose">取 消</el-button>
				<el-button
					type="primary"
					@click="onConfirm"
				>提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { DatePicker, Dialog, Form, FormItem, Input } from 'element-ui';
import CommonTitle from './CommonTitle';

export default {
	name: 'FollowUpDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		}
	},
  components: {
    'el-dialog': Dialog,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-date-picker': DatePicker,
    CommonTitle
  },
	data() {
		return {
			followForm: {
				date: '',
				follow: ''
			},
			rules: {
				date: [
					{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}
				],
				follow: [
					{ required: true, message: '请输入事故后续处理', trigger: 'blur' }
				]
			}
		};
	},
	methods: {
		/**
		 * @desc: 确认
		 */
		onConfirm() {
			this.$refs.followForm.validate(valid => {
				if (!valid) return false;
				// console.log('验证通过12222222', this.followForm)
				this.$emit('submitFollow', this.followForm);
			});
		},
		/**
		 * @desc: 关闭
		 */
		onClose() {
			this.$emit('input', false);
		}
	}
};
</script>

<style lang="less" scoped>
:deep(.el-dialog) {
  .el-dialog__body{
    padding-top: 10px;
  }
}
</style>